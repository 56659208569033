/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Athiti";
}

@font-face {
  font-family: "Athiti";
  src: url("/assets/fonts/Athiti/Athiti-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Athiti";
  src: url("/assets/fonts/Athiti/Athiti-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body,
body:before {
  position: fixed;
  background-image: url(https://fs.cdnxn.com/lsm99king/king/img/bg/Default/bg-mobile.png);
  /* background-image: url(https://fs.cdnxn.com/lsm99king/king/img/bg.webp); */
  background-repeat: repeat-y;
  background-size: cover;
  background-position: top center;
  height: 100vh;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

.btn-line {
  width: 200px;
  height: 35px;
  color: rgb(255, 255, 255) !important;
  text-decoration: none !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  background-color: #06c755;
  border-radius: 10px;
  margin: 0 auto;
  padding-right: 5px;
}

.-img-game {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 170px;
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px; */
}

.bg-boot-icon {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/num.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.-img-game:hover {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame-hover.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  height: 170px;
  bottom: 0px;
}

.-active-game {
  width: 100px;
  height: 175px;
  border-radius: 5px;
}
a {
  text-decoration: none !important;
}
.-img-wallet {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  margin: auto;
}
.box-icon-bt {
  text-align: center;
}
.box-icon-bt.active {
  text-align: center;
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg111.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  padding-bottom: 0.5rem;
}
.box-icon-bt:hover {
  text-align: center;
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg111.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  padding-bottom: 0.5rem;
}
.-img-wallet:hover {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g1.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 55px;
  height: 55px;
  margin: auto;
}

.-active-wallet {
  width: 70px;
  height: 70px;
  margin: auto;
}

.-img-deposit {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g2.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  margin: auto;
}

.-img-deposit:hover {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g2.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 50px;
  height: 50px;
}

.-active-deposit {
  width: 70px;
  height: 70px;
}

/* .-img-withdraw {
  background-image:
    url('https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-withdraw.webp');
  background-repeat: no-repeat;
  background-position: bottom;
  width: 28px;
  height: 25px;
}

.-img-withdraw:hover {
  background-image:
    url('https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-withdraw-hover.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 27px;
  height: 25px;
}

.-active-withdraw {
  width: 60px;
  height: 61px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.-img-affiliates {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g3.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
  margin: auto;
}

.-img-affiliates:hover {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g3.gif");
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  background-size: 100% 100%;
}

.-active-affiliates {
  width: 70px;
  height: 70px;
  border-radius: 20px;
}

.-img-promotion {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g4.png");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
  margin: auto;
}

.-img-promotion:hover {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/Default/g4.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 50px;
  height: 50px;
}
span.text-bt-x.active {
  color: #ffff;
}
span.text-bt-x {
  text-align: center;
  font-size: 10px;
  color: #040404;
  font-weight: 600;
}
.-active-promotion {
  width: 70px;
  height: 70px;
}

.group-btn-item {
  display: grid;
  justify-content: center;
}

.mt-menu {
  margin-top: -13px;
}

.your-text-container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.-form-input-mb {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-input-mb.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px;
  color: #000;
  display: flex;
  text-align: center;
}

.deposit-withdraw-bg {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-depositPC.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  height: 50px;
  margin: 4rem auto 0rem;
  align-items: center;
}

.deposit-withdraw-bg2 {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-withdrawPC.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  height: 50px;
  margin: 4rem auto 0rem;
  align-items: center;
}

.deposit-withdraw-bg-mb {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-withdrawMB.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  padding: 0.3rem 0.5rem;
  width: 100%;
  height: 45px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}

.deposit-withdraw-bg2-mb {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-depositMB.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: space-around;
  padding: 0.5rem;
  width: 100%;
  height: 45px;
  align-items: center;
  position: fixed;
  top: 88px;
  left: 0;
}
.bg-tab-text-- {
  background-image: url(https://fs.cdnxn.com/lsm99king/king/img/bg-depositMB.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1rem 0rem;
}
.group-deposit-- {
  width: 350px;
  margin: 1rem auto;
}
.box-deposit-bank-- {
  margin: 1rem;
}
.group-from-Payment {
  width: 330px;
  margin: 1rem auto;
}
.text-deposit-withdraw1 {
  color: #eeaf4a !important;
  text-decoration: none !important;
  font-size: 1rem;
}

.text-deposit-withdraw0 {
  color: #b5b5b5 !important;
  text-decoration: none !important;
}

.text-deposit-withdraw0:hover {
  color: #eeaf4a !important;
  text-decoration: none !important;
  font-size: 1rem;
}

.span-deposit-withdraw {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  /* margin-right: 8rem; */
}
.mt-top- {
  margin-top: 1rem;
}
.bg-box-icon.active {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/at-bg.png");
}
.bg-box-icon {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/a-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 60px;
}
span.text-at-.active,
span.text-at-:hover {
  color: #f4f071;
}
span.text-at- {
  font-size: 15px;
  font-weight: 600;
}
.casino-icon,
.slot-icon,
.lotto-icon,
.sport-icon,
.casino-icon:hover,
.slot-icon:hover,
.sport-icon:hover,
.lotto-icon:hover,
.casino-icon-active,
.slot-icon-active,
.sport-icon-active,
.lotto-icon-active {
  width: 85px;
  height: 75px;
  margin-top: -1.5rem;
}
.casino-icon {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/btn-game-filter-casino.webp'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.slot-icon {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/btn-game-filter-slot.webp'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.lotto-icon {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/btn-game-filter-lotto.webp'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.sport-icon {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/btn-game-filter-sport.webp'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.casino-icon:hover {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.slot-icon:hover {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.sport-icon:hover {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.lotto-icon:hover {
  /* background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif'); */
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/* .casino-icon-active {
  width: 350px;
  height: 200px;
}

.slot-icon-active {
  width: 350px;
  height: 200px;
}

.sport-icon-active {
  width: 350px;
  height: 200px;
}

.lotto-icon-active {
  width: 350px;
  height: 200px;
} */

.group-games-icon-t {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
}
.Deposit-from-num {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}
.group-deposit--- {
  padding-bottom: 10rem;
}
/* Responsive Design */
@media all and (min-width: 900px) {
  .group-deposit-- {
    width: 900px;
  }
  .group-deposit--- {
    margin: 1rem auto;
    display: flex;
  }
  .running-message-- {
    margin-top: 10rem;
  }
  .pta-t {
    padding-left: 8rem !important;
    padding-right: 7rem !important;
  }
  .deposit-withdraw-bg2-mb {
    left: 50%;
    width: 900px;
    transform: translate(-50%, -50%);
    top: 110px;
  }
  .group-deposit--- {
    padding-bottom: 0rem;
  }
}
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .mt-menu {
    margin-top: -8px;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  .-frame-bottom-menu-wallet,
  .-frame-bottom-menu-deposit,
  .-frame-bottom-menu-withdraw,
  .-frame-bottom-menu-promotionList,
  .-frame-bottom-menu-game {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-bottom-menu-mb.webp") !important;
    max-height: 6rem;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    max-width: unset !important;
    margin-right: 2.5rem;
  }

  .-img-game {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 150px;
    position: absolute;
    left: unset;
    transform: unset;
    bottom: 0px;
  }

  .-active-game {
    width: 100px;
    height: 150px;
    border-radius: 20px;
    position: absolute;
    left: unset;
    transform: unset;
    bottom: 0px;
  }

  .-img-game:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame-hover.gif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
  }

  .-active-wallet {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-deposit {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-affiliates {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-promotion {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .mt-menu {
    margin-top: -8px;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  .-frame-bottom-menu-wallet,
  .-frame-bottom-menu-deposit,
  .-frame-bottom-menu-withdraw,
  .-frame-bottom-menu-promotionList,
  .-frame-bottom-menu-game {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-bottom-menu-mb.webp") !important;
    max-height: 6rem;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    max-width: unset !important;
    margin-right: 2.5rem;
  }
  .group-games-icon-t {
    column-gap: 0.5rem;
  }
  .mt-top- {
    margin-top: 10rem;
  }
  .bg-box-icon {
    width: 75px;
    height: 35px;
    padding: 0px 2px;
  }
  span.text-at- {
    font-size: 11px;
  }
  .casino-icon:hover,
  .slot-icon:hover,
  .sport-icon:hover,
  .lotto-icon:hover,
  .casino-icon-active,
  .slot-icon-active,
  .sport-icon-active,
  .lotto-icon-active {
    width: 50px;
    height: 50px;
    margin-top: -1rem;
  }
  .casino-icon,
  .slot-icon,
  .lotto-icon,
  .sport-icon {
    width: 30px;
    height: 30px;
    margin-top: -0.5rem;
  }
  /* .casino-icon {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .slot-icon {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .lotto-icon {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .sport-icon {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .casino-icon:hover {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .slot-icon:hover {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .sport-icon:hover {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .lotto-icon:hover {
    background-image: url('https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .casino-icon-active {
    width: 70px;
    height: 70px;
  }

  .slot-icon-active {
    width: 70px;
    height: 70px;
  }

  .sport-icon-active {
    width: 70px;
    height: 70px;
  }

  .lotto-icon-active {
    width: 70px;
    height: 70px;
  } */

  .-img-game {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 150px;
    position: absolute;
    left: unset;
    transform: unset;
    bottom: 0px;
  }

  .-active-game {
    width: 100px;
    height: 150px;
    border-radius: 20px;
    position: absolute;
    left: unset;
    transform: unset;
    bottom: 0px;
  }

  .-img-game:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame-hover.gif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
  }

  /* .-active-wallet {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-deposit {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-affiliates {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-promotion {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  } */
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .container-menu {
    width: 480px;
    max-width: 480px;
    margin: 0 auto;
  }

  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }

  .mt-menu {
    margin-top: -8px;
  }

  .casino-icon {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .slot-icon {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }
  .sport-icon {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }
  .lotto-icon {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/menu/MenuGame/dg4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .casino-icon:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .slot-icon:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .sport-icon:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .lotto-icon:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 70px;
    height: 70px;
  }

  .casino-icon-active {
    width: 70px;
    height: 70px;
  }

  .slot-icon-active {
    width: 70px;
    height: 70px;
  }

  .sport-icon-active {
    width: 70px;
    height: 70px;
  }

  .lotto-icon-active {
    width: 70px;
    height: 70px;
  }

  .-active-wallet {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-deposit {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-affiliates {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-active-promotion {
    width: 55px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .-img-game {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 150px;
    position: absolute;
    left: unset;
    transform: unset;
    bottom: 0px;
  }

  .-active-game {
    width: 100px;
    height: 150px;
    border-radius: 20px;
    position: absolute;
    left: unset;
    transform: unset;
    bottom: 0px;
  }

  .-img-game:hover {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame-hover.gif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
  }

  .-frame-bottom-menu-wallet,
  .-frame-bottom-menu-deposit,
  .-frame-bottom-menu-withdraw,
  .-frame-bottom-menu-promotionList,
  .-frame-bottom-menu-game {
    background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-bottom-menu-mb.webp") !important;
    max-height: 6rem;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
    max-width: unset !important;
    margin-right: 2.5rem;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .mt-menu {
    margin-top: -9px;
  }
}

/* Theme custom */
.-desktop {
  display: none;
}

@media only screen and (min-width: 768px) {
  .-mobile {
    display: none;
  }

  .-desktop {
    display: block;
  }

  .-king-left {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  #wrapper .-btn-02,
  #wrapper .-btn-01,
  #wrapper .-form-style .-form-input {
    padding-top: 0 !important;
  }

  body,
  body:before {
    background: url("https://fs.cdnxn.com/lsm99king/king/img/bg/Default/bg-desktop.png");
    /* background: url('https://fs.cdnxn.com/lsm99king/king/img/bg-dt.webp'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100%;
  }
}

#wrapper .-form-style .-text-validate {
  color: #ff3939 !important;
}

#wrapper .bg-gray-500 {
  background-color: #212121 !important;
}

.-header .-hamburger {
  left: unset;
  right: 13px;
  top: 13px;
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/icon-hamburger.webp");
  background-size: contain;
  height: 40px;
  width: 40px;
}

.-header .-hamburger.-active {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp");
}
.pta-t {
  padding-left: 0rem;
  padding-right: 13rem;
}
.-header .-noti {
  position: absolute;
  right: 55px;
  top: 14px;
  height: 40px;
  width: 40px;
}

.-header .-logo {
  top: 5px;
  left: 4rem;
  width: 100px;
}

.-header .-logo + .-text-header,
.-open-menu-hamburger .-header .-logo {
  display: block;
}

.-header .-profile {
  position: absolute;
  left: 50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-header .-wallet {
  background-image: url("https://fs.cdnxn.com/lsm99king/king/img/bg-label-credit.webp");
  background-position: left top;
  background-size: contain;
}

.text-truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-bar-ac {
  background: url(https://fs.cdnxn.com/lsm99king/king/img/tabactivebarmb.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
}

.box-frame {
  width: 360px;
  margin: auto;
}

.box-frame-top {
  padding-top: 1rem;
}

span.act1 {
  padding-left: 3rem;
}

span.act2 {
  padding-right: 3rem;
}

@media only screen and (min-width: 915px) {
  .box-frame {
    width: 914px;
  }
}

@media only screen and (min-width: 768px) {
  .-header .-profile {
    position: absolute;
    left: unset;
    right: 4.5rem;
  }
}

/* .-theme-menu-bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
} */

/* .-theme-menu-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  z-index: 20;
  width: 75%;
  height: 75px;
} */

.-theme-menu-bottom > .-menu-bottom-item {
  width: 25%;
  height: 100%;
}

.-theme-menu-playgame {
  z-index: 20;
  width: 20%;
  height: 144px;
}

.-menu-bottom-playgame {
  height: 144px;
}

@media only screen and (max-width: 1439px) {
  /* .casino-icon, .slot-icon, .lotto-icon, .sport-icon, .casino-icon:hover, .slot-icon:hover, .sport-icon:hover, .lotto-icon:hover, .casino-icon-active, .slot-icon-active, .sport-icon-active, .lotto-icon-active {
    width: 135px;
    height: 135px;  
  } */
  .pta-t {
    width: 900px;
    margin-left: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  /* .-theme-menu-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 144px;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
  } */

  .-theme-menu-bottom {
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* margin-left: 1rem; */
    /* margin-top: 0;
    z-index: 20;
    width: 80%;
    height: 75%; */
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }

  .-theme-menu-bottom > .-menu-bottom-item {
    width: 100%;
    height: 23%;
  }

  .-theme-menu-playgame {
    width: 100px;
    height: 180px;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .-theme-menu-bottom-wrapper {
    position: fixed;
    bottom: -0.5rem;
    right: -0.1rem;
    background: url(https://fs.cdnxn.com/lsm99king/king/img/bg-bottom-menu-promotion-dt.webp);
    background-size: 60% 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
  }
  .-menu-bottom-playgame {
    height: 144px;
  }
}

.-main-menu {
  width: 80%;
  left: unset;
  right: 0;
  background: linear-gradient(
    128.69deg,
    rgba(0, 0, 0, 0.8) 2.7%,
    rgba(0, 0, 0, 0.8) 98.45%
  ) !important;
  backdrop-filter: blur(3px) !;
}

@media only screen and (min-width: 768px) {
  .-main-menu {
    width: 30%;
  }
}

.-group-main-menu {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.-group-main-menu > .-menu-item {
  margin: 0.5rem 0;
}

.-color-02 {
  color: #ffbe45;
}

#wrapper .-section-wallet .-list-btn a.-active {
  background-color: #ffbe45;
}

#wrapper .-section-wallet .-list-btn a strong {
  color: #000;
}

#wrapper .-your-account .-items .-item {
  background: none;
}

#wrapper a:not([class^="-btn-"]) {
  color: #fff;
}

#wrapper .-list-img a {
  box-shadow: unset;
  border: none;
}

@media only screen and (min-width: 768px) {
}

#wrapper .-form-style .-form-radio input:checked ~ .-form-input {
  background-color: #eeaf4a;
  color: #000;
  font-weight: bold;
}
#wrapper .-form-style .-form-radio input:checked ~ .--form-input {
  background-color: #eeaf4a;
  color: #000;
  font-weight: bold;
}
#wrapper .-form-style .-form-input {
  background-color: #000;
  border: 1px solid #eeaf4a;
}
#wrapper .-form-style .--form-input {
  background-color: #000;
  border: 1px solid #eeaf4a;
  width: 75px;
  border-radius: 10px;
  height: 40px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
input.--form-input {
  display: flex;
  margin: auto;
  width: 250px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#wrapper .-list-game a > img {
  box-shadow: unset;
  border: none;
}

.-list-game:not(.splide) {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.-content-popup:before {
  background: rgba(0, 0, 0, 0.8);
}

.bg-theme {
  background: linear-gradient(
    128.69deg,
    rgba(0, 0, 0, 0.8) 2.7%,
    rgba(0, 0, 0, 0.8) 98.45%
  );
}

#wrapper .-table-style th {
  color: #858585;
  background-color: unset;
}

#wrapper .-btn-01 {
  background: url(https://fs.cdnxn.com/lsm99king/king/img/btn-affiliate.webp);
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-width: 0;
  width: 100%;
  box-shadow: 0 0 0px rgba(var(--shadow-btn-01), 0.7);
}

#wrapper .-list-noti .-item.-unread,
#wrapper .-list-noti .-item,
#wrapper .-list-history .-item {
  background-color: unset;
  border: unset;
}

#wrapper .-box-history-data .-nav-data,
#wrapper .-box-history-data .-nav-data a.active,
#wrapper .-box-summary-data .-nav-data a.active {
  background: unset;
  background-color: unset;
  border: unset;
  box-shadow: unset;
}

#wrapper .-box-history-data .-nav-data a.active {
  color: #eeaf4a;
}

#wrapper .-list-history .-item:hover {
  background-color: rgba(238, 175, 74, 0.3);
}

.-content-login .-form-style {
  margin-top: 0;
}

#wrapper .-header {
  border: unset;
}

#wrapper .-header .-logo {
  width: 65px;
  height: 60px;
}
.group-noti- {
  display: grid;
  font-size: 11px;
  padding: 2px 10px;
  grid-template-columns: 2fr 1fr;
  column-gap: 1rem;
  line-height: 15px;
}
.top-mgt {
  margin-top: 8rem !important;
}
.-your-account .-items .-item .-inner {
  background-image: unset;
}

.-content-popup {
  left: unset;
  right: 0;
}

app-main-menu .-main-menu {
  width: 60%;
}

@media only screen and (min-width: 768px) {
  app-main-menu .-main-menu {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  app-main-menu .-main-menu {
    width: 40%;
  }
}

.-header {
  padding-right: 0;
}

.-section-recommended-promotion .-list-img a {
  border-radius: unset;
}

#wrapper a[href^="tel"] {
  color: black !important;
}

@media only screen and (max-width: 500px) {
  /*--- FOOTER ---*/
  .-theme-menu-bottom-wrapper {
    height: 160px;
    padding-bottom: 1rem;
    padding-right: 2rem;
  }

  .-theme-menu-bottom-wrapper {
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 100%;
  }

  .-theme-menu-bottom {
    display: flex;
    align-items: center;
    padding-bottom: 2.5rem;
    z-index: 20;
    width: 100%;
    height: 140px;
    justify-content: center;
    margin: auto;
    column-gap: 0.2rem;
  }

  .-active-game {
    height: 145px;
    border-radius: 5px;
  }

  .-frame-bottom-menu-wallet .container {
    max-width: 100% !important;
  }

  .-frame-bottom-menu-wallet,
  .-frame-bottom-menu-deposit,
  .-frame-bottom-menu-withdraw,
  .-frame-bottom-menu-promotionList,
  .-frame-bottom-menu-game {
    background-size: 100% 100% !important;
  }

  .-header .-logo {
    top: 5px;
    left: 3.5rem;
    width: 90px;
  }
}
.mt-9a {
  margin-top: 9rem;
}

@media only screen and (max-width: 450px) {
  /*--- FOOTER ---*/
  .-theme-menu-bottom-wrapper {
    height: 160px;
    padding-bottom: 1rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 410px) {
  /*--- FOOTER ---*/
  .-theme-menu-bottom-wrapper {
    height: 160px;
    padding-bottom: 1rem;
    padding-right: 2rem;
  }
}

.-box-btn.-group-promotion-btn-see-all {
  background: url(https://fs.cdnxn.com/lsm99king/king/img/bg-button-primary.webp);
  background-size: 100% 100%;
  text-align: center;
  padding: 1rem 0;
}

.-box-btn.-group-promotion-btn-see-all a {
  text-decoration: none !important;
  font-weight: bold;
}

/*--- Games Listing ---*/
.-content-playing.-game-listing-container .relative.ng-star-inserted a img {
  width: 100% !important;
  height: 100% !important;
}

#hamburger-move {
  position: absolute;
  z-index: 1000;
  top: 0.5rem;
  right: 0.5rem;
}

.-hamburger-move {
  height: 75px;
  width: 75px;
  background-image: url(./assets/img/icons/icon-btn-move.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  cursor: pointer;
}

app-modal-alert-message.wallet-alert-msgs.ng-star-inserted
  p.text-sm.leading-5.text-gray-500 {
  background-color: #000;
  color: #fff;
  padding: 1rem;
  font-weight: 600;
  color: #fff !important;
}

app-notification-bar {
  width: 100%;
  z-index: 9999;
}

.-content-playing {
  margin-top: 60px;
}

/*--- ---*/
app-forgotpassword {
  position: relative;
  height: 100dvh;
  display: block;
}

main#main-content.-forgot-password {
  width: 328px;
  height: 216px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main#main-content.-forgot-password .-frame-contact-box {
  background-size: 100% 100%;
  background-repeat: round;
  background-position: top center;
  width: 325px;
  height: 100%;
}

main#main-content.-forgot-password a.-btn-close.-btn-close-forgot-password {
  width: 110%;
  display: block;
  height: 45px;
}

main#main-content.-forgot-password a.-btn-close.-btn-close-forgot-password img {
  float: right;
}

.display-none {
  display: none;
}

.isShowLinkLineLogin {
  background-color: #44d244;
  border-color: #e2f5e2;
  box-shadow: 0 0 20px #49e049;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  height: 2.5rem;
  width: 100%;
  border-radius: 10px;
  font-weight: 500;
  color: #fff;
  text-decoration: none !important;
  font-size: 15px;
}

.-deposit-snackbar-running {
  margin-top: 2rem !important;
}

.block-is-upload-slip {
  display: block;
}

.block-is-upload-slip {
  display: block;
}

.flex-is-upload-slip {
  display: flex;
}

.-is-upload-slip-box {
  width: 60%;
  max-width: 1290px;
}

.-upload-slip-left,
.-upload-slip-right {
  width: 50%;
}

@media only screen and (max-width: 2090px) {
  .-is-upload-slip-box {
    width: 75%;
    max-width: 1290px;
  }

  .-upload-slip-left,
  .-upload-slip-right {
    width: 40%;
  }
}

@media only screen and (max-width: 900px) {
  .-upload-slip-left,
  .-upload-slip-right {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  body,
  body:before {
    /* background: url('https://fs.cdnxn.com/lsm99king/king/img/team/bg.webp'); */
    /* background-image: url(https://fs.cdnxn.com/lsm99king/king/img/bg.webp); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }

  .-mobile.-mb-deposit-section .-content-bank .mt-7.-is-upload-slip {
    margin-top: 10rem !important;
    margin-bottom: -8rem !important;
  }

  .-frame-snackbar {
    margin-top: 6rem;
  }

  .flex-is-upload-slip {
    display: block;
  }

  .-is-upload-slip-box {
    width: 328px;
  }

  .-upload-slip-left,
  .-upload-slip-right {
    width: 100%;
  }
}

.-slip-backdrop {
  position: relative;
  width: 100%;
  height: auto;
}

.-slip-backdrop-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 50%);
}

.-errorMsgPriview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 18px;
  font-weight: 600;
  width: 90%;
  text-align: center;
}
