
.introjs-tooltip {
    .introjs-tooltip-header{
        h1.introjs-tooltip-title {
            color: black;
        }        
    }
    .introjs-tooltiptext {
        color: black;
    }
}